import React, { useState } from "react"

import { Link } from "gatsby"
import logo from "../images/fs-logo.png"

const Header = () => {
  const [isExpanded, toggleExpansion] = useState(false)
  return (
    <header className="header">
      <div className="flex flex-wrap items-center justify-between align-center max-w-4xl p-4 mx-auto md:p-4">
        <Link to="/" className="flex items-center align-center pt-2">
          <div className="flex items-center text-white no-underline mt-2">
            <img
              src={logo}
              style={{ maxHeight: `1.7rem` }}
              alt={`ФС-Технологии`}
            />
          </div>
        </Link>

        <button
          className="items-center block px-3 py-2 text-white border border-black rounded md:hidden"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className="w-3 h-3 fill-current"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Меню</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>

        <nav
          className={`${
            isExpanded ? `block` : `hidden`
          } md:block md:items-center w-full md:w-auto`}
          style={{
            fontFamily: `Montserrat`,
          }}
        >
          {[
            {
              route: `/stroitelstvo-domov`,
              title: `Строительство домов`,
            },
            {
              route: `/remont-krovli`,
              title: `Кровля и фасады`,
            },
                        {
              route: `https://septic.fs-tech.ru/`,
              title: `Септик для дома`,
            },
            {
              route: `/uslugi`,
              title: `Услуги`,
            },

            {
              route: `/contacts`,
              title: `Контакты`,
            },
          ].map(link => (
            <Link
              className="block text-black no-underline md:inline-block md:mt-0 md:ml-6"
              key={link.title}
              to={link.route}
              style={{ fontSize: `0.9rem`, color: `#F1F1F2` }}
            >
 {link.title}
            </Link>
          ))}
        </nav>
      </div>
    </header>
  )
}

export default Header
