import React from "react"

import Layout from "../components/layout"
import Map from "../components/map"
import Form from "../components/form"

import { FiPhone } from "react-icons/fi"
import { MdPlace } from "react-icons/md"
import { HiOutlineMail } from "react-icons/hi"

const Contacts = () => {
  return (
    <Layout>
      <div className="mt-4">
        <div>
          <div className="p-4 flex flex-col align-center justify-center justify-between md:flex-row">
            <span
              className="flex flex-row"
              style={{ fontFamily: `Montserrat` }}
            >
              <MdPlace size={25} className="text-white" />
              <p className="text-white pl-2">
                Марий Эл, г.Йошкар-Ола,<br></br> ул. Панфилова, 33А, оф.210
              </p>
            </span>

            <span
              className="flex flex-row"
              style={{ fontFamily: `Montserrat` }}
            >
              <FiPhone size={25} className="text-white mr-2" />
              <div className="flex flex-col">
                <a href="tel:+79677570025" className="text-white">
                  +7 967 757 00 25
                </a>
              </div>
            </span>
            <span
              className="flex flex-row"
              style={{ fontFamily: `Montserrat` }}
            >
              <HiOutlineMail size={25} className="text-white" />
              <p className="text-white pl-2">sale@fs-tech.ru</p>
            </span>
          </div>
        </div>
        <Map />
        <Form />
      </div>
    </Layout>
  )
}

export default Contacts
